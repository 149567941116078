import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import {Layout} from "../components/Layout";
import { graphql, Link, useStaticQuery } from "gatsby";
import Button from '../components/buttons/button'
import ProductBannerNoLine from "../components/product_banners_no_line/product_banners_no_line";
import { SalWrapper } from "react-sal";
import Banner from "../imgs/banner-cuenta-joven-desktop.jpg";
import BannerMobile from "../imgs/banner-cuenta-joven-mobile.jpg";
import { apiRequest } from "../apiRquest/apiRequest";
/*import "../pages/pages.css";*/
import "./cuenta-joven.css";
import { Helmet } from "react-helmet";
import Img1 from "../imgs/cuenta_joven_1.jpg";
import Img2 from "../imgs/cuenta_joven_2.jpg";
import Img3 from "../imgs/cuenta_joven_3.jpg";
import Icon1 from "../imgs/apertura.png";
import Icon2 from "../imgs/formulario.png";
import Icon3 from "../imgs/persona.png";
// import Icon4 from "../imgs/perfil.png";
import Icon4 from "../imgs/invertir.png";
import Faq from "../components/faq/faq";

let text = `Cuenta para <strong>menores de 13 a 17 años</strong>`;
const pageTitle = 'Cuenta Joven';
const pageDesc = 'Te contamos todo lo que necesitás saber para crear tu cuenta y empezar a invertir en Balanz. Es más simple de lo que pensás ¡Comenzá a invertir ahora!';
const CuentaJovenPage = () => {
    const FaqsData = useStaticQuery(graphql`
            {
                allFaqs(
                    filter: {category_id: {in: 25}},
                    sort: {order: ASC, fields: [category_id, order]}
                ) {
                    nodes {
                        category_id
                        id
                        answer
                        question
                    }
                }
            }
        `);
    
        const [faq, setFaq] = useState({
            list: FaqsData.allFaqs.nodes
        });
        
    const [isMobile, setIsMobile] = useState(false);
     useEffect(() => {
        const handleResize = () => {
          if (window.innerWidth <= 768) {
            setIsMobile(true);
          } else {
            setIsMobile(false);
          }
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
          window.removeEventListener("resize", handleResize);
        };
      }, [isMobile]);
      useEffect(() => {
        apiRequest
            .get("/v1/faq/25")
            .then((res) => {
                const data = []
                res.data.data.forEach(d => {
                    data.push({
                        question: d.question,
                        answer: d.answer
                    })

                })

                setFaq((old) => ({...old, list: data}));
            })
            .catch((err) => {
                console.log(err);
            })
    }, [setFaq])
    const page = (
        <>
            <ProductBannerNoLine
        bannerContainerClassName="bannerCuentaJoven"
        bannerTrasparecy="bannerMask-noblue"
        productTitle="Cuenta <br> Joven"
        productTitleBlueClassName="productTitleEmpleos"
        bannerImage={Banner}
        bannerImageClassName="ReportBannerImage"
        textContainerClassName="textContainer"
        bannerText={text}
        bannerTextClassName="bannerTextInversiones"
        bannerImageMobile={BannerMobile}
        mobile={isMobile}
      />
            <SalWrapper>
            <section className="fullwidth cuenta-joven pb-0">
                <Container>
                    <Row className=" pt-3">
                        <h3 className="mt-2"
                            data-sal="slide-up"
                            data-sal-delay="500"
                            data-sal-duration="500"
                        ><span className="primary-color">¡Fomentá el futuro financiero!</span></h3>

                        <p className="base-text pad-responsive"
                           data-sal="fade"
                           data-sal-delay="320"
                           data-sal-duration="200">
                            Abrí una cuenta para jóvenes entre 13 a 17 años y enseñales el valor del dinero.</p>

                        <h3 className="mt-2"
                            data-sal="slide-up"
                            data-sal-delay="500"
                            data-sal-duration="500"
                        ><span className="primary-color">¿Por qué abrir una cuenta joven en Balanz?</span></h3>

                        <p className="base-text pb-2"
                           data-sal="fade"
                           data-sal-delay="320"
                           data-sal-duration="200">
                            En Balanz, estamos convencidos que la <span className="fw-bold">educación financiera</span> es una 
                            herramienta clave para el empoderamiento de las nuevas generaciones.
                            Aprender a gestionar sus finanzas no solo les permite tomar <span className="fw-bold">decisiones 
                            económicas inteligentes</span>, sino que también los prepara para <span className="fw-bold">construir un 
                            futuro más seguro y próspero.</span></p>
                        <p className="base-text pb-5"
                           data-sal="fade"
                           data-sal-delay="320"
                           data-sal-duration="200">
                            Por eso, en Balanz nos comprometemos a acompañar a los jóvenes en este 
                            camino de transformación financiera de la mano de <a href="https://balanz.com/balanz-university" target="_blank"><span className="fw-bold"><u>Balanz University</u></span></a> y nos 
                            enorgullece formar parte del crecimiento y desarrollo financiero de las nuevas 
                            generaciones, trabajando juntos para conseguir una<span className="fw-bold"> sociedad más informada 
                            y preparada.</span></p>
                    </Row>
                    
                </Container>
                <div className="bg-blue-joven-first">
                    <Container>
                        <Row>
                            <h3 className="bg-blue-title">5 beneficios de abrir su cuenta en Balanz</h3>
                           
                        </Row>
                    </Container>
                </div>
                <section className='section-home-five px-3 container-xl py-5'>
                    <article className='row article-top py-3 g-0'>
                        <div className='col'>
                            <img src={Img1} alt="Employee working"/>
                        </div>
                        <div className='col my-4 my-md-auto col_text ps-md-4'>
                        <div className="pad-left">
                            <h3 >Educación financiera</h3>
                            <p>Enseñales desde chicos a tomar decisiones inteligentes con el dinero acompañados con el apoyo de <a href="https://balanz.com/balanz-university" target="_blank"><span className="fw-bold"><u>Balanz University.</u></span></a>
                                </p>
                        </div>
                        </div>
                    </article>
                    <article className='row article-bottom g-0'>
                        <div className='col my-4 my-md-auto col_text'>
                            <div className="pad-left">
                                <h3>Independencia económica</h3>
                                <p>Ayudalos a alcanzar sus metas a largo plazo.</p>
                                <h3 className="mt-3">Seguridad financiera</h3>
                                <p>Protégelo de la inflación y construí un fondo para su futuro.</p>
                                </div>
                        </div>
                        <div className='col'>
                            <img src={Img2} alt="Employee working"/>
                        </div>
                    </article>
                    <article className='row article-top py-3 g-0'>
                        <div className='col'>
                            <img src={Img3} alt="Employee working"/>
                        </div>
                        <div className='col my-4 my-md-auto col_text ps-md-4'>
                        <div className="pad-left">
                            <h3 >Asesoramiento personalizado</h3>
                            <p>Atención a medida. </p>
                            <h3 className="mt-3">Seguridad y confianza</h3>
                            <p>Accedé a su cuenta para supervisar su uso y 
                            recibí un informe mensual sobre sus operaciones.</p>
                        </div>
                        </div>
                    </article>
                </section>
                
                <div className="bg-lt-grey pb-5">
                    <Container>
                        <Row className="pt-5 text-center">
                            
                            <h3 className="primary-color"
                                data-sal="fade"
                                data-sal-delay="200"
                                data-sal-duration="200"
                            >¿Cómo abrir una cuenta joven?</h3>
                            <p className="base-text"
                               data-sal="fade"
                               data-sal-delay="300"
                               data-sal-duration="200"
                            >
                                Para abrir una cuenta joven, es necesario que el padre, madre <br></br>
                                o tutor del menor cuente con una cuenta comitente en Balanz.
                                <br></br>
                                Si todavía no sos cliente, podés abrirla <a href="https://balanz.com/abrir-cuenta-2.aspx?bam_campaign=1385" target="_blank"><span className="fw-bold"><u>acá.</u></span></a>

                            </p>
                            
                            <h3 className="pt-3 primary-color"
                                data-sal="fade"
                                data-sal-delay="200"
                                data-sal-duration="200"
                            ><span style={{fontWeight: "400"}}>Si ya tenés tu cuenta comitente en Balanz el proceso es </span>fácil y rápido. </h3>
                        </Row>
                        <Row className="justify-content-center pt-3">
                                    <div className=" box-proceso">
                                        <img src={Icon1} alt="icon"/>
                                        <p>Empezá el proceso 
                                            de apertura desde 
                                            el banner en 
                                            plataforma.</p>
                                    </div>
                                    <div className=" box-proceso">
                                        <img src={Icon2} alt="icon"/>
                                        <p>Completá el 
                                        formulario de 
                                        apertura con los 
                                        datos del menor.</p>
                                    </div>
                                    <div className=" box-proceso">
                                        <img src={Icon3} alt="icon"/>
                                        <p>Le enviaremos un 
                                        mail al menor para 
                                        que valide su 
                                        identidad.</p>
                                    </div>
                                    <div className=" box-proceso">
                                        <img src={Icon4} alt="icon"/>
                                        <p>Ya puede 
                                        empezar a invertir.</p>
                                    </div>
                                
                        </Row>
                        
                    </Container>
                </div>
                <div className="bg-blue-joven">
                    <Container>
                        <Row>
                            <h3 className="bg-blue-title">¿En qué productos se podrá invertir?</h3>
                            <p className="bg-blue-text">Instrumentos de renta fija
                            y renta variable en pesos y en dólares: </p>
                            <div className="box-border">
                                <a className="bg-blue-border" href="https://balanz.com/inversiones/fondos/money-market/" target="_blank">FCI MONEY MARKET</a>
                                <a className="bg-blue-border" href="https://balanz.com/inversiones/cedears/" target="_blank">CEDEARS</a>
                                <a className="bg-blue-border" href="https://balanz.com/inversiones/acciones/" target="_blank"> ACCIONES</a>
                                <a className="bg-blue-border" href="https://balanz.com/inversiones/obligaciones-negociables/" target="_blank">OBLIGACIONES NEGOCIABLES</a>
                            </div>
                            <div className="box-border">
                                <a className="bg-blue-border" href="https://balanz.com/inversiones/bonos/" target="_blank">TÍTULOS PÚBLICOS</a>
                                <a className="bg-blue-border" href="https://balanz.com/inversiones/cauciones/ " target="_blank">CAUCIONES COLOCADORAS</a>
                            </div>
                        </Row>
                    </Container>
                </div>
            </section>
            <section className="fullwidth preguntas-frecuentes">
                <Container>
                    <Row>
                    <Faq faq={faq} customClass={'cuenta-joven-faq'} />
                    </Row>
                </Container>
            </section>
            <section>
                <Container>
                    <Row>
                        <p className="disclaimer-text">
                        Apertura de cuenta sujeta a condiciones de contratación de Balanz Capital Valores SAU ("BCV") y Balanz Sociedad Gerente de Fondos Comunes de Inversión SAU ("BSGFCI"). El menor de edad adolescente titular de la subcuenta comitente solo podrá realizar las operaciones detalladas y aceptadas oportunamente en el convenio de apertura de cuenta y las normas de la Comisión Nacional de Valores (la “CNV”) (N.T. 2013 y modificatorias), las cuales serán concertadas en segmentos de negociación con prioridad precio-tiempo. En cuanto a Fondos Comunes de Inversión ("FCI"), el menor de edad adolescente solo podrá suscribir y rescatar cuotapartes de FCI abiertos de "Mercado de Dinero" conforme la definición contenida en el artículo 4° inciso b), Sección II, Capítulo II, Título V de las normas de la CNV (N.T. 2013 y modificatorias). BCV y BSGFCI no aseguran rendimientos de ningún tipo ni cuantía. Las inversiones del cliente están sujetas a las fluctuaciones de precios de los mercados en los que opere. Para mayor información, contáctese con nuestros asesores. ALYC N° 210 y ACDI N° 62 ante la CNV. IGJ N° 13981 del L. 40, T. - SOCIEDAD POR ACCIONES. CUIT: 30-71063067-0. AAPIC N° 32 ante la CNV. IGJ N° 12.486 del L. 18, T- DE SOCIEDADES POR ACCIONES. CUIT: 33-70812346-9.

                        </p>
                    </Row>
                </Container>
            </section>
            
            </SalWrapper>
        </>
    );

    return (
        <main>
            <Layout title={pageTitle} description={pageDesc} childrem={page}  category={"cuenta-joven"}></Layout>
        </main>
    );
};

// Step 3: Export your component
export default CuentaJovenPage;